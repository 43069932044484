import { Feature, Geometry } from "geojson";
import { ApiClient } from "./ApiClient";
import L, { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { BorderType, Classification, SecurityType, UsageType, WaterbodyType, WaterOwnerType } from "../types/waterbody.traits";
import { SolarPanelType, SystemType } from "../types/solar.traits";
import { DEFAULT_ECONOMIC_CONFIGURATION, EconomicConfiguration } from "../types/report.traits";


// When a network request returns a data packet, we can tell it to return the data as a type. We can't use Project directly for that
// because it will be missing everything except the fields.
interface ProjectData {
  id: number;
  name: string;
  features: Feature<Geometry>[];
  systemType: string;
  operationalLife: number;
  panelEfficiency: number;
  owner: string;
  waterOwner: string;
  waterOwnerType: string;
  waterOwnerContactInfo: string;
  usageType: string;
  borderType: string;
  waterbodyType: string;
  classification: string;
  securityType: string[];
  solarPanelType: string;
  losses: number;
  panelTilt: number;
  panelAzimuth: number;
  dcToAcRatio: number;
  groundCoverageRatio: number;
  inverterEfficiency: number;
  bifaciality: boolean;
  albedo: number;
  soiling: number;
  economicConfig: EconomicConfiguration;
}

export class Project {
  constructor(
    public id: number,
    public name: string,
    public features: Feature<Geometry>[],
    public systemType: SystemType,
    public operationalLife: number,
    public panelEfficiency: number,
    public owner: string,
    public waterOwner: string,
    public waterOwnerType: WaterOwnerType,
    public waterOwnerContactInfo: string,
    public usageType: UsageType,
    public borderType: BorderType,
    public waterbodyType: WaterbodyType,
    public classification: Classification,
    public securityType: SecurityType[],
    public solarPanelType: SolarPanelType,
    public losses: number,
    public panelTilt: number,
    public panelAzimuth: number,
    public dcToAcRatio: number,
    public groundCoverageRatio: number,
    public inverterEfficiency: number,
    public bifaciality: boolean,
    public albedo: number,
    public soiling: number,
    public economicConfig: EconomicConfiguration,
  ) {
  }

  static fromRaw(inst: ProjectData): Project {
    return new Project(
      inst.id,
      inst.name,
      inst.features,
      SystemType.fromId(inst.systemType),
      inst.operationalLife,
      inst.panelEfficiency,
      inst.owner,
      inst.waterOwner,
      WaterOwnerType.fromId(inst.waterOwnerType),
      inst.waterOwnerContactInfo,
      UsageType.fromId(inst.usageType),
      BorderType.fromId(inst.borderType),
      WaterbodyType.fromId(inst.waterbodyType),
      Classification.fromId(inst.classification),
      inst.securityType.map(SecurityType.fromId),
      SolarPanelType.fromId(inst.solarPanelType),
      inst.losses,
      inst.panelTilt,
      inst.panelAzimuth,
      inst.dcToAcRatio,
      inst.groundCoverageRatio,
      inst.inverterEfficiency,
      inst.bifaciality,
      inst.albedo,
      inst.soiling,
      inst.economicConfig,
    );
  }

  toFeatureGroup(color?: string): L.FeatureGroup {
    const commonOptions: L.PolylineOptions = {};
    if (color) {
      commonOptions.color = color;  // Must only be set if there is a color, otherwise it overrides the default
    }

    const featureGroup = new L.FeatureGroup();
    for (const feature of this.features) {
      switch (feature.geometry.type) {
        case 'Polygon':
          featureGroup.addLayer(new L.Polygon(feature.geometry.coordinates.map((poly) => poly.map((coord) => new L.LatLng(coord[1], coord[0]))), commonOptions));
          break;
        case 'LineString':
          featureGroup.addLayer(new L.Polyline(feature.geometry.coordinates.map((coord) => new L.LatLng(coord[1], coord[0])), commonOptions));
          break;
        default:
          throw new Error("Unknown geometry type: " + feature.geometry.type);
      }
    }
    return featureGroup;
  }

  getMarker(): L.Marker {
    return L.marker(this.toFeatureGroup().getBounds().getCenter(), { icon: new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] }) })
  }

  getTooltipContent(): ((layer: L.Layer) => L.Content) | L.Tooltip | L.Content {
    return `Project: ${this.name || '...'}<br/>
    System Type: ${this.systemType.displayName || '...'}<br/>
    Water Owner: ${this.waterOwner || '...'}<br/>
    Water Owner Type: ${this.waterOwnerType.displayName || '...'}<br/>
    Water Owner Contact Info: ${this.waterOwnerContactInfo || '...'}<br/>
    Usage Type: ${this.usageType.displayName || '...'}<br/>
    Border Type: ${this.borderType.displayName || '...'}<br/>
    Waterbody Type: ${this.waterbodyType.displayName || '...'}<br/>
    Classification: ${this.classification.displayName || '...'}<br/>
    Security Type: ${this.securityType.map((s) => s.displayName).join(', ') || '...'}`;
  }
}

export class ProjectClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getProject(id: number): Promise<Project> {
    console.log('getting project', id);
    if (!id || id < 0) {
      throw new Error('invalid project id ' + id);
    }
    return Project.fromRaw((await this.apiClient.get<ProjectData>('api/project/' + id)).data);
  }

  async getProjects(): Promise<Project[]> {
    console.log('getting projects');
    return (await this.apiClient.get<ProjectData[]>('api/project')).data.map(Project.fromRaw);
  }

  async createOrUpdateProject(project: Project): Promise<Project> {
    var cleaned: object = Object.fromEntries(Object.entries(project)
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => (k !== 'id' || v >= 0)) // id must be undefined or >= 0
      .map(([k, v]) => {
        if (typeof v === 'object') {
          return [k, v.id || v];
        }
        return [k, v]
      }));
    console.log('Creating/updating project', cleaned);
    return Project.fromRaw((await this.apiClient.post<ProjectData>('api/project', cleaned)).data);
  }

  async deleteProject(id: number): Promise<void> {
    console.log('deleting project', id);
    if (!id || id < 0) {
      throw new Error('invalid project id ' + id);
    }
    await this.apiClient.delete('api/project/' + id);
  }
}

export const DEFAULT_PROJECT_VALUES = new Project(
  -1,
  '',
  [],
  SystemType.CANOPY,
  25,
  0.18,
  '',
  '',
  WaterOwnerType.UNSPECIFIED,
  '',
  UsageType.UNSPECIFIED,
  BorderType.UNSPECIFIED,
  WaterbodyType.UNSPECIFIED,
  Classification.UNSPECIFIED,
  [],
  SolarPanelType.STANDARD,
  4,
  12,
  180,
  1.2,
  0.75,
  96,
  false,
  0.07,
  5,
  DEFAULT_ECONOMIC_CONFIGURATION,
)
