export class SolarPanelType {
  static STANDARD = new SolarPanelType('standard', 'Standard', 0);
  static PREMIUM = new SolarPanelType('premium', 'Premium', 1);
  static THIN_FILM = new SolarPanelType('thin-film', 'Thin Film', 2);

  constructor(public readonly id: string, public readonly displayName: string, public readonly code: number) { }

  static allValues(): SolarPanelType[] {
    return [
      SolarPanelType.STANDARD,
      SolarPanelType.PREMIUM,
      SolarPanelType.THIN_FILM,
    ];
  }

  static fromId(id: string): SolarPanelType {
    const found = SolarPanelType.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export class SystemType {
  static CANOPY = new SystemType('canopy', 'Canopy', .95, 1.02);
  static FLOATING = new SystemType('floating', 'Floating', .7, 1.03);
  static GROUND_MOUNT = new SystemType('ground-mount', 'Ground Mount', .4, 1);

  constructor(public readonly id: string, public readonly displayName: string, public readonly coverageRatio: number, public readonly powerMultiplier: number) { }

  static allValues(): SystemType[] {
    return [SystemType.CANOPY, SystemType.FLOATING, SystemType.GROUND_MOUNT];
  }

  static fromId(id: string): SystemType {
    const found = SystemType.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}
