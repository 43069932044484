import './App.css';
import { Outlet, RouterProvider, createBrowserRouter, json } from 'react-router-dom';
import { ProjectEditor } from './components/projects/ProjectEditor';
import { projectLoader, projectsLoader, reportsLoader, waterbodiesLoader, waterbodyLoader } from './generic/loaders';
import OAuthSuccessRedirect from './routes/auth/oauth-redirect';
import { useCookies } from 'react-cookie';
import { Profile, currentUserDataLoader } from './components/profile/Profile';
import React from 'react';
import { ApiClient } from './utils/ApiClient';
import { Layout } from './generic/Layout';
import { ProjectBrowser } from './components/projects/ProjectBrowser';
import { redirectToLogin } from './utils/AuthUtils';
import { LandingMap } from './components/landing/LandingMap';
import { DiscoveryTool } from './components/discovery/DiscoveryTool';
import { ProjectReport } from './components/projects/report/ProjectReport';
import { ProjectBrowserTable } from './components/projects/ProjectTableView';
import { GovUnitsExplorationTool } from './components/explore/Explore';

export const TOKEN_COOKIE_NAME = 'token';

export class LoggedInUser {
  username: string;
  apiClient: ApiClient;
  logout: () => void;

  constructor(token: string, logout: () => void) {
    this.username = JSON.parse(atob(token.split('.')[1])).username;
    this.apiClient = new ApiClient(token);
    this.logout = logout;
  }
}

export const LoggedInUserContext = React.createContext<LoggedInUser | null>(null);

function RequireAuth({ children }: { children: React.ReactNode }): JSX.Element {
  const user = React.useContext(LoggedInUserContext);
  if (!user) {
    redirectToLogin();
    return <></>;
  }
  return <>{children}</>;
}

export function App() {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);

  const login = (token: string) => {
    const { username, exp } = JSON.parse(atob(token.split('.')[1]));
    const expiresAtDate = new Date(exp * 1000);
    console.log(`logging in user ${username} to expire at ${expiresAtDate}`, username);
    removeCookie(TOKEN_COOKIE_NAME, { path: '/' });
    setCookie(TOKEN_COOKIE_NAME, token, { expires: expiresAtDate, secure: true, sameSite: 'strict', path: '/' });
  }

  const logout = () => {
    removeCookie(TOKEN_COOKIE_NAME);
  }

  const user = cookies.token ? new LoggedInUser(cookies.token, logout) : null;

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <LandingMap/>,
        },
        {
          path: '/profile',
          element: <RequireAuth><Profile /></RequireAuth>,
          loader: async () => { return currentUserDataLoader(user) },
        },
        {
          path: '/explore/govunits',
          element: <RequireAuth><GovUnitsExplorationTool /></RequireAuth>,
        },
        {
          path: '/discover',
          element: <RequireAuth><DiscoveryTool /></RequireAuth>,
        },
        {
          path: '/discover/new',
          element: <RequireAuth><DiscoveryTool /></RequireAuth>,
        },
        {
          path: '/discover/edit/:id',
          element: <RequireAuth><DiscoveryTool /></RequireAuth>,
          loader: async ({ params }) => { return waterbodyLoader(parseInt(params.id!), user) },
        },
        {
          path: '/data/waterbodies/:waterbodyId?',
          element: <RequireAuth><></></RequireAuth>,
          loader: async ({ params }) => { 
            if (params.waterbodyId) {
              return waterbodyLoader(parseInt(params.waterbodyId), user)
            }
            return waterbodiesLoader(user) },
          action: async ({ request, params }) => {
            let formData = await request.formData();
            
            switch (formData.get('intent')) {
              case 'delete':
                if (params.waterbodyId) {
                  await user?.apiClient.getWaterbodyClient().deleteWaterbody(parseInt(params.waterbodyId!));
                }
                return Response.redirect('/discover');  // Might be better to let the form handle the redirect? Not sure how.
            
              case 'save':
                //TODO: make this actually handle saving
                return null;

              default:
                console.log('unknown intent');
                // This may not work, but is the suggested approach.
                throw json(
                  { message: 'Invalid intent' },
                  { status: 400 }
                );
            }
          }
        },
        {
          path: '/projects',
          element: <RequireAuth><Outlet /></RequireAuth>,
          children: [
            { 
              index: true,
              loader: async () => { return projectsLoader(user) },
              element: <ProjectBrowser />,
            },
            {
              path: 'new',
              element: <ProjectEditor />,
            },
            {
              path: 'table',
              element: <ProjectBrowserTable />,
              loader: async () => { return projectsLoader(user) },
            },
            {
              path: ':id',
              id: 'project_selected',
              element: <Outlet />,
              loader: async ({ params }) => { return projectLoader(parseInt(params.id!), user) },
              action: async ({ request, params }) => {
                // let formData = await request.formData();

                switch (request.method) {
                  case 'DELETE':
                    await user?.apiClient.getProjectClient().deleteProject(parseInt(params.id!));
                    return Response.redirect('/projects');  // Might be better to let the form handle the redirect? Not sure how.
                
                  case 'POST':
                    //TODO: make this actually handle saving
                    return null;
    
                  default:
                    console.log('unknown request method', request.method);
                    // This may not work, but is the suggested approach.
                    throw json(
                      { message: 'Invalid request method' },
                      { status: 400 }
                    );
                }
              },
              children: [
                {
                  path: 'edit',
                  element: <ProjectEditor />,
                },
                {
                  path: 'report',
                  element: <ProjectReport />,
                  // For when we no longer need to set annualEnergyProductionKWh manually
                  // loader: async ({ params }) => { return reportsLoader(parseInt(params.id!), user) },
                }
              ]
            },
          ]
        },
      ]
    },
    {
      path: '/oauth-success-redirect/:accessToken/:from?',
      element: <OAuthSuccessRedirect login={login} />,
    },
  ]);

  return (
    <LoggedInUserContext.Provider value={user}>
      <RouterProvider router={router} />
    </LoggedInUserContext.Provider>
  );
}
