import { Feature, Polygon } from 'geojson';
import L from 'leaflet';

// FV-17
const EVAPORATION_MULTIPLIER = 2_146_000_000 // kg/year/km^2
const M_TO_KM_MULTIPLIER = 0.000001;
export const L_TO_ACRE_FEET_MULTIPLIER = 8.1071319379e-7;

/**
 * 
 * @param features The features to sum
 * @returns An Area in square meters
 */
export function sumAreas(features: L.Layer[]): number {
  var total = 0;
  features.forEach((feature) => {
    if (feature instanceof L.Polygon) {
      const latlngs = feature.getLatLngs()[0] as L.LatLng[];
      total += L.GeometryUtil.geodesicArea(latlngs);
    }
  })
  return total;
}

// Area given in meters, evaporation returned in kg
export function calculateYearlyEvaporation(area: number): number {
  return areaSquareKmFromSquareM(area) * EVAPORATION_MULTIPLIER;
}

/**
 * 
 * @param volume In liters
 * @returns A string representation of the volume in liters, kiloliters, megaliters, gigaliters, or teraliters
 */
export function readableVolumeMetric(volume: number): string {
  return scaleSI(volume, 'L');
}

/**
 * Generates a human-readable string of a number using SI terms for factors of 1000.
 * 
 * This will not work for units to a power (like km²)
 * 
 * @param value A number to be made human-readable
 * @param unit A string representing the unit of the value, like "W" for watts
 * @returns A string representation of the value in SI units
 */
export function scaleSI(value: number, unit: string): string {
  if (value >= 1_000_000_000_000) {
    return (value * 0.000_000_000_001).toFixed(2) + ' T' + unit;
  }
  if (value >= 1_000_000_000) {
    return (value * 0.000_000_001).toFixed(2) + ' G' + unit;
  }
  if (value >= 1_000_000) {
    return (value * 0.000_001).toFixed(2) + ' M' + unit;
  }
  if (value >= 1000) {
    return (value * 0.001).toFixed(2) + ' k' + unit;
  }
  return value.toFixed(0) + ' ' + unit;
}

// Given an area in square meters, return the area in square 
export function areaSquareKmFromSquareM(area: number): number {
  return area * M_TO_KM_MULTIPLIER;
}

// Modified from Leaflet's Readable Area function, but they insist on hectares.
export function readableAreaMetric(area: number): string {
  if (area >= 100000) {  // Display areas larger than 0.1 km^2 in km^2.
    return areaSquareKmFromSquareM(area).toFixed(3) + ' km²';
  } else {
    return area.toFixed(0) + ' m²';
  }
}

export function layerToFeature(layer: L.Layer): Feature {
  if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
    return layer.toGeoJSON();
  }

  throw new Error('Unsupported layer type: ' + layer);
}

export function latLngBoundsToGeoJsonPolygon(bounds: L.LatLngBounds): Polygon {
  return {
    type: 'Polygon',
    coordinates: [
      [
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      ],
    ],
  }
}
