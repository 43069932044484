// See https://nivo.rocks/ for info on charts.

import { useRouteLoaderData } from "react-router-dom";
import { Project } from "../../../utils/ProjectClient";
import { Accordion, Card, Col, Container, Row, Table } from "react-bootstrap";
import { readableAreaMetric, readableVolumeMetric, sumAreas, L_TO_ACRE_FEET_MULTIPLIER, scaleSI } from "../../../utils/GeoUtils";
import { Power, Water } from "react-bootstrap-icons";
import { FeatureGroup, MapContainer, WMSTileLayer } from "react-leaflet";
import styles from '../../../generic/mapping/Map.module.css'
import React, { useContext } from "react";
import L from "leaflet";
import { getUSGSURL, USGS_ATTR } from "../../../generic/mapping/BackgroundLayersControl";
import { PVWattsClient, PVWattsClientError, PVWattsResponseContent } from "../../../utils/PVWattsClient";
import { estimatePowerOutputForProject, readablePower } from "../../../utils/PowerUtils";
import { EconomicAnalysis, TechnicalAnalysis } from "../../../types/report.traits";
import { LoggedInUserContext } from "../../../App";
import { PowerGenerationReport } from "./graphs/power";
import { YearlyEconomicsGraphs } from "./graphs/econ";

export function ProjectReport() {
  const project = useRouteLoaderData('project_selected') as Project;
  const loggedInUser = useContext(LoggedInUserContext);
  // const report = useLoaderData() as EconomicAnalysis;
  const [map, setMap] = React.useState<L.Map | null>(null);
  const [projectFG, setProjectFG] = React.useState<L.FeatureGroup | null>(null);
  const [pvwattsMonthlyData, setPvwattsMonthlyData] = React.useState<PVWattsResponseContent>();
  const [error, setError] = React.useState<string>();

  // Temporary until we dont have to pass annualEnergyProduction to the server
  const [econReport, setEconReport] = React.useState<EconomicAnalysis>();
  const [techReport, setTechReport] = React.useState<TechnicalAnalysis>();

  React.useEffect(() => {
    if (loggedInUser && project && pvwattsMonthlyData) {
      loggedInUser.apiClient.getReportClient().getEconReport(project.id, pvwattsMonthlyData.outputs.ac_annual).then((r) => { console.log(r); setEconReport(r) });
      loggedInUser.apiClient.getReportClient().getTechReport(project.id).then((r) => { console.log(r); setTechReport(r) })
    }
  }, [loggedInUser, project, pvwattsMonthlyData])

  React.useEffect(() => {
    if (map) {
      map.flyToBounds(project.toFeatureGroup().getBounds(), { animate: false });
    }
  }, [map, project]);

  React.useEffect(() => {
    if (projectFG) {
      projectFG.clearLayers();
      projectFG.addLayer(project.toFeatureGroup());
    }
  }, [projectFG, project]);

  React.useEffect(() => {
    new PVWattsClient().getPVWattsMonthlyData(project).then(setPvwattsMonthlyData).catch(e => {
      console.error(e);
      if (e instanceof PVWattsClientError) {
        setError(e.message);
      }
    });
  }, [project]);

  if (error) {
    return (
      <Card className='m-1'>
        <Card.Body>
          <Card.Title>Error loading</Card.Title>
          <Card.Text>{error}</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const area = sumAreas(project.toFeatureGroup().getLayers());
  const powerGeneration = estimatePowerOutputForProject(project);
  const powerGenerationMin = powerGeneration * 0.80;
  const powerGenerationMax = powerGeneration * 1.20;
  const yearlyEvaporation = techReport?.annualEvaporationSavingsAcreftPerYear || 0;

  return (
    <Container fluid="lg">
      <h1 className='m-3'>Project <b>{project.name}</b></h1>
      <Row className='flex-fill text-center' style={{ minHeight: '0' }}>
        <Col className='col-9'>
          <MapContainer
            center={new L.LatLng(0, 0)}
            zoom={0}
            maxZoom={25}
            minZoom={4}
            zoomSnap={0.5}
            className={styles['map']}
            zoomControl={false}
            ref={setMap}
            dragging={false}>
            <FeatureGroup ref={setProjectFG} />
            <WMSTileLayer url={getUSGSURL('USGSImageryTopo')} layers='0' attribution={USGS_ATTR} maxNativeZoom={16} maxZoom={map?.getMaxZoom()} />
          </MapContainer>
        </Col>
        <Col>
          <Row className='text-success'>
            <Row>
              <h2>Installed Power</h2>
            </Row>
            <Row>
              <Power size={64} />
            </Row>
            <Row>
              <h2>{readablePower(powerGeneration)}</h2>
              <h5>{readablePower(powerGenerationMin)} - {readablePower(powerGenerationMax)}</h5>
            </Row>
          </Row>
          <Row className='text-primary'>
            <Row>
              <h2>Water Conserved</h2>
            </Row>
            <Row>
              <Water size={64} />
            </Row>
            <Row>
              <h3>{readableVolumeMetric(yearlyEvaporation / L_TO_ACRE_FEET_MULTIPLIER)} ({(yearlyEvaporation).toFixed(0)} Acre-ft)</h3>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Project Details</Card.Title>
              <Card.Text>Creator: {project.owner}</Card.Text>
              <Card.Text>System Type: {project.systemType.displayName}</Card.Text>
              <Card.Text>Water Owner: {project.waterOwner}</Card.Text>
              <Card.Text>Water Owner Type: {project.waterOwnerType.displayName}</Card.Text>
              <Card.Text>Water Owner Contact Info: {project.waterOwnerContactInfo}</Card.Text>
              <Card.Text>Usage Type: {project.usageType.displayName}</Card.Text>
              <Card.Text>Border Type: {project.borderType.displayName}</Card.Text>
              <Card.Text>Waterbody Type: {project.waterbodyType.displayName}</Card.Text>
              <Card.Text>Classification: {project.classification.displayName}</Card.Text>
              <Card.Text>Security Type: {project.securityType.map((s) => s.displayName).join(', ')}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          {/* TODO: This card should calculate the stats from DB calls instead of these rough estimates */}
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Estimated Stats:</Card.Title>
              <Card.Text>Area: {readableAreaMetric(area)}</Card.Text>
              <Card.Text>Power: {readablePower(powerGeneration)} ({readablePower(powerGenerationMin)} - {readablePower(powerGenerationMax)})</Card.Text>
              <Card.Text>Annual Evaporation Savings: {"~" + readableVolumeMetric(yearlyEvaporation)} ({(yearlyEvaporation * L_TO_ACRE_FEET_MULTIPLIER).toFixed(1)} Acre-ft)</Card.Text>
            </Card.Body>
          </Card>
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Power Generation</Card.Title>
              {pvwattsMonthlyData ?
                (<>
                  <Card.Text>Annual AC Production: {scaleSI(pvwattsMonthlyData.outputs.ac_annual * 1000, 'Wh')}</Card.Text>
                  <Card.Text>Annual Solar Radiation: {scaleSI(pvwattsMonthlyData.outputs.solrad_annual * 1000, 'Wh/m2/day')}</Card.Text>
                  <Card.Text>Capacity Factor: {pvwattsMonthlyData.outputs.capacity_factor.toFixed(2)}</Card.Text>
                </>) : <></>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Power Generation over time:</Card.Title>
              <PowerGenerationReport monthlyData={pvwattsMonthlyData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* TODO: This card should calculate the stats from DB calls instead of these rough estimates */}
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Economic Analysis:</Card.Title>
              <Table>
                <tbody>
                  <tr>
                    <th>Total Direct Capital Cost per Watt</th>
                    <td>${econReport?.totalDirectCapitalCostPerWatt.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Direct Capital Costs</th>
                    <td>${econReport?.totalDirectCapitalCosts.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Indirect Capital Cost per Watt</th>
                    <td>${econReport?.totalIndirectCapitalCostPerWatt.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Indirect Capital Costs</th>
                    <td>${econReport?.totalIndirectCapitalCosts.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Sales Tax Cost</th>
                    <td>${econReport?.totalSalesTaxCost.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Capital Cost</th>
                    <td>${econReport?.totalCapitalCost.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Capital Cost per Watt</th>
                    <td>${econReport?.totalCapitalCostPerWatt.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Capital Cost per Watt (AC)</th>
                    <td>${econReport?.totalCapitalCostPerWattAC.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Installed Cost (Subsidized)</th>
                    <td>${econReport?.totalInstalledCostSubsidized.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Installed Cost per Watt (Subsidized)</th>
                    <td>${econReport?.totalInstalledCostSubsidizedPerWatt.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Total Installed Cost per Watt (Subsidized, AC)</th>
                    <td>${econReport?.totalInstalledCostSubsidizedPerWattAC.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Annual Operation and Maintenance Cost</th>
                    <td>${econReport?.annualOperationAndMaintenanceCost.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Loan Amount</th>
                    <td>${econReport?.loanAmount.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Water Cost Savings per Year</th>
                    <td>${econReport?.waterCostSavingsPerYear.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Mitigation Cost Savings per Year</th>
                    <td>${econReport?.mitigationCostSavingsPerYear.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Land Acquisition Savings</th>
                    <td>${econReport?.landAquisitionSavings.toLocaleString()}</td>
                  </tr>
                </tbody>
              </Table>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Lifetime Finances</Accordion.Header>
                  <Accordion.Body>
                    <Table>
                      <tbody>
                        <tr>
                          <th>Year</th>
                          <th>Degradation</th>
                          <th>Energy Yield</th>
                          <th>Electricity Value per kWh</th>
                          <th>Revenue</th>
                          <th>Operation and Maintenance Cost</th>
                          <th>Water Cost</th>
                          <th>Mitigation Cost</th>
                          <th>Federal Income Tax Cost</th>
                          <th>State Income Tax Cost</th>
                          <th>Principal Payment</th>
                          <th>Interest Payment</th>
                          <th>Principal and Interest Payment</th>
                          <th>Gross Profit</th>
                          <th>Cash Flow</th>
                          <th>Net Profit Cumulative</th>
                          <th>NPV</th>
                          <th>NPV Cumulative</th>
                        </tr>
                        {econReport?.lifetimeFinances.map((year, index) => (
                          <tr key={index}>
                            <td>{year.year}</td>
                            <td>{year.degredation.toFixed(3)}</td>
                            <td>{readablePower(year.energyYield)}h</td>
                            <td>${year.electricityValuePerKWh.toFixed(2)}</td>
                            <td>${year.revenue.toFixed(2)}</td>
                            <td>${year.operationAndMaintenanceCost.toFixed(2)}</td>
                            <td>${year.waterCost.toFixed(2)}</td>
                            <td>${year.mitigationCost.toFixed(2)}</td>
                            <td>${year.federalIncomeTaxCost.toFixed(2)}</td>
                            <td>${year.stateIncomeTaxCost.toFixed(2)}</td>
                            <td>${year.principalPayment.toFixed(2)}</td>
                            <td>${year.interestPayment.toFixed(2)}</td>
                            <td>${year.principalAndInterestPayment.toFixed(2)}</td>
                            <td>${year.grossProfit.toFixed(2)}</td>
                            <td>${year.cashFlow.toFixed(2)}</td>
                            <td>${year.netProfitCumulative.toFixed(2)}</td>
                            <td>${year.npv.toFixed(2)}</td>
                            <td>${year.npvCumulative.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className='m-1'>
            <Card.Body>
              <Card.Title>Yearly Economics:</Card.Title>
              <YearlyEconomicsGraphs lifetimeFinances={econReport?.lifetimeFinances} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}