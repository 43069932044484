// Options were specified originally in https://wattsonwater.atlassian.net/browse/FV-35

export class WaterOwnerType {
  static UNSPECIFIED = new WaterOwnerType('unspecified', 'Unspecified');
  static INDIVIDUAL = new WaterOwnerType('individual', 'Individual');
  static COMPANY = new WaterOwnerType('company', 'Company');
  static GOVERNMENT = new WaterOwnerType('government', 'Government');
  static OTHER = new WaterOwnerType('other', 'Other');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): WaterOwnerType[] {
    return [
      WaterOwnerType.UNSPECIFIED,
      WaterOwnerType.INDIVIDUAL,
      WaterOwnerType.COMPANY,
      WaterOwnerType.GOVERNMENT,
      WaterOwnerType.OTHER
    ];
  }

  static fromId(id: string): WaterOwnerType {
    const found = this.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export class UsageType {
  static UNSPECIFIED = new UsageType('unspecified', 'Unspecified');
  static WATER_SUPPLY = new UsageType('water_supply', 'Water Supply');
  static CSP = new UsageType('csp', 'Control, Stabilization, Protection');
  static HYDROELECTRIC = new UsageType('hydroelectric', 'Hydroelectric');
  static PUMPED_HYDROELECTRIC = new UsageType('pumped_hydroelectric', 'Pumped Hydroelectric');
  static IRRIGATION = new UsageType('irrigation', 'Irrigation');
  static AQUACULTURE = new UsageType('aquaculture', 'Aquaculture');
  static FF_POWER_RESERVOIR = new UsageType('ff_power_reservoir', 'FF Power Reservoir');
  static OTHER = new UsageType('other', 'Other');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): UsageType[] {
    return [
      UsageType.UNSPECIFIED,
      UsageType.WATER_SUPPLY,
      UsageType.CSP,
      UsageType.HYDROELECTRIC,
      UsageType.PUMPED_HYDROELECTRIC,
      UsageType.IRRIGATION,
      UsageType.AQUACULTURE,
      UsageType.FF_POWER_RESERVOIR,
      UsageType.OTHER
    ];
  }

  static fromId(id: string): UsageType {
    const found = this.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export class BorderType {
  static UNSPECIFIED = new BorderType('unspecified', 'Unspecified');
  static EARTH = new BorderType('earth', 'Earth');
  static LINED = new BorderType('lined', 'Lined');
  static CONCRETE = new BorderType('concrete', 'Concrete');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): BorderType[] {
    return [
      BorderType.UNSPECIFIED,
      BorderType.EARTH,
      BorderType.LINED,
      BorderType.CONCRETE
    ];
  }

  static fromId(id: string): BorderType {
    const found = this.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export class Classification {
  static UNSPECIFIED = new Classification('unspecified', 'Unspecified');
  static NATURAL = new Classification('natural', 'Natural');
  static ARTIFICIAL = new Classification('artificial', 'Artificial');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): Classification[] {
    return [
      Classification.UNSPECIFIED,
      Classification.NATURAL,
      Classification.ARTIFICIAL
    ];
  }

  static fromId(id: string): Classification {
    const found = this.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export class WaterbodyType {
  static UNSPECIFIED = new WaterbodyType('unspecified', 'Unspecified');
  static LAKE = new WaterbodyType('lake', 'Lake');
  static RIVER = new WaterbodyType('river', 'River');
  static STREAM = new WaterbodyType('stream', 'Stream');
  static POND = new WaterbodyType('pond', 'Pond');
  static RESERVOIR = new WaterbodyType('reservoir', 'Reservoir');
  static DAMMED_RESERVOIR = new WaterbodyType('dammed_reservoir', 'Dammed Reservoir');
  static MAJOR_CANAL = new WaterbodyType('major_canal', 'Major Canal');
  static MINOR_CANAL = new WaterbodyType('minor_canal', 'Minor Canal');
  static DITCH = new WaterbodyType('ditch', 'Ditch');
  static HARBOUR = new WaterbodyType('harbour', 'Harbour');
  static DELTA = new WaterbodyType('delta', 'Delta');
  static OTHER = new WaterbodyType('other', 'Other');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): WaterbodyType[] {
    return [
      WaterbodyType.UNSPECIFIED,
      WaterbodyType.LAKE,
      WaterbodyType.RIVER,
      WaterbodyType.STREAM,
      WaterbodyType.POND,
      WaterbodyType.RESERVOIR,
      WaterbodyType.DAMMED_RESERVOIR,
      WaterbodyType.MAJOR_CANAL,
      WaterbodyType.MINOR_CANAL,
      WaterbodyType.DITCH,
      WaterbodyType.HARBOUR,
      WaterbodyType.DELTA,
      WaterbodyType.OTHER
    ];
  }

  static fromId(id: string): WaterbodyType {
    const found = this.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}


export class SecurityType {
  static NONE = new SecurityType('none', 'None');
  static GATED = new SecurityType('gated', 'Gated');
  static FENCED = new SecurityType('fenced', 'Fenced');
  static FENCED_BARBED_WIRE = new SecurityType('fenced_barbed_wire', 'Fenced with Barbed Wire');
  static NIGHT_GUARDED = new SecurityType('night_guarded', 'Night Guarded');
  static ALWAYS_GUARDED = new SecurityType('always_guarded', 'Always Guarded');
  static SECURITY_CAMERAS = new SecurityType('security_cameras', 'Security Cameras');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): SecurityType[] {
    return [
      SecurityType.NONE,
      SecurityType.GATED,
      SecurityType.FENCED,
      SecurityType.FENCED_BARBED_WIRE,
      SecurityType.NIGHT_GUARDED,
      SecurityType.ALWAYS_GUARDED,
      SecurityType.SECURITY_CAMERAS
    ];
  }

  static fromId(id: string): SecurityType {
    const found = SecurityType.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}
