import { scaleSI, sumAreas } from "./GeoUtils";
import { Project } from "./ProjectClient";


const KW_PER_M2 = 1;

/** 
 * Given an area in square meters, return the estimated ideal power output.
 *
 * @param areaM2 Area in square meters
 * @param coverage The percentage of the area that is solar panel
 * @returns Power in killowatts
 */
export function estimatePowerOutputFromArea(areaM2: number, coverage: number, efficiency: number): number {
  return areaM2 * coverage * KW_PER_M2 * efficiency;
}

/**
 * Estimate power production of a project.
 * 
 * @param project Project to estimate power for
 * @returns Power in killowatts
 */
export function estimatePowerOutputForProject(project: Project): number {
  return project.systemType.powerMultiplier * estimatePowerOutputFromArea(sumAreas(project.toFeatureGroup().getLayers()), project.systemType.coverageRatio, project.panelEfficiency);
}

/**
 * 
 * @param power In kilowatts
 * @returns A string representation of the power in kilowatts, megawatts, gigawatts, or terawatts
 */
export function readablePower(power: number): string {
  if (power < 1000) {  // Display wattage larger than 1 MW in MW
    return power.toFixed(3) + ' kW';
  }
  return scaleSI(power * 1000, 'W');
}