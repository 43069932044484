import { Point } from "geojson";
import { ApiClient } from "./ApiClient";
import { BorderType, UsageType, WaterOwnerType, Classification, WaterbodyType } from "../types/waterbody.traits";


// When a network request returns a data packet, we can tell it to return the data as a type. We can't use Waterbody directly for that
// because it will be missing everything except the fields.
interface WaterbodyData {
  id: number;
  name: string;
  location: Point;
  owner: string;
  waterOwner: string;
  waterOwnerType: string;
  waterOwnerContactInfo: string;
  usageType: string;
  borderType: string;
  waterbodyType: string;
  classification: string;
  securityType: string[];
}

export class Waterbody {

  constructor(
    public location: Point,
    public id: number | undefined = undefined,
    public name: string | undefined = undefined,
    public owner: string | undefined = undefined,
    public waterOwner: string | undefined = undefined,
    public waterOwnerType: WaterOwnerType = WaterOwnerType.UNSPECIFIED,
    public waterOwnerContactInfo: string | undefined = undefined,
    public usageType: UsageType = UsageType.UNSPECIFIED,
    public borderType: BorderType = BorderType.UNSPECIFIED,
    public waterbodyType: WaterbodyType = WaterbodyType.UNSPECIFIED,
    public classification: Classification = Classification.UNSPECIFIED,
    public securityType: string[] = [],
  ) {
  }

  static fromRaw(inst: WaterbodyData): Waterbody {
    return new Waterbody(
      inst.location,
      inst.id,
      inst.name,
      inst.owner,
      inst.waterOwner,
      WaterOwnerType.fromId(inst.waterOwnerType),
      inst.waterOwnerContactInfo,
      UsageType.fromId(inst.usageType),
      BorderType.fromId(inst.borderType),
      WaterbodyType.fromId(inst.waterbodyType),
      Classification.fromId(inst.classification),
      inst.securityType
    );
  }

  getTooltipContent(): JSX.Element {
    return (
      <>
        Waterbody Name: {this.name || '...'}<br />
        Owner: {this.waterOwner || '...'}<br />
        Owner Type: {this.waterOwnerType.displayName}<br />
        Owner Contact Info: {this.waterOwnerContactInfo || '...'}<br />
        Usage Type: {this.usageType.displayName}<br />
        Border Type: {this.borderType.displayName}<br />
        Waterbody Type: {this.waterbodyType.displayName}<br />
        Classification: {this.classification.displayName}<br />
        Security Type: {this.securityType.join(', ')}<br />
      </>
    );
  }
}

export class WaterbodyClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getWaterbody(id: number): Promise<Waterbody> {
    console.log('getting waterbody', id);
    return Waterbody.fromRaw((await this.apiClient.get<WaterbodyData>('api/waterbody/' + id)).data);
  }

  async getWaterbodies(): Promise<Waterbody[]> {
    console.log('getting waterbody');
    return (await this.apiClient.get<WaterbodyData[]>('api/waterbody')).data.map(Waterbody.fromRaw);
  }

  async createOrUpdateWaterbody(waterbody: Waterbody): Promise<Waterbody> {
    console.log('Cleaning waterbody', waterbody);
    var cleaned = Object.fromEntries(Object.entries(waterbody)
      .filter(([k, v]) => v !== undefined)
      .map(([k, v]) => {
        if (typeof v === 'object') {
          return [k, v.id || v];
        }
        return [k, v]
      }));
    console.log('Creating/updating waterbody', cleaned);
    return Waterbody.fromRaw((await this.apiClient.post<WaterbodyData>('api/waterbody', cleaned)).data);
  }

  async deleteWaterbody(id: number): Promise<void> {
    console.log('deleting waterbody', id);
    await this.apiClient.delete('api/waterbody/' + id);
  }

}