import { Card } from "react-bootstrap";
import { calculateYearlyEvaporation, L_TO_ACRE_FEET_MULTIPLIER, readableAreaMetric, readableVolumeMetric, sumAreas } from "../../utils/GeoUtils";
import { Project } from "../../utils/ProjectClient";
import { MapInfoBox, MapInfoBoxContainer } from "../../generic/mapping/MapBoxes";
import { estimatePowerOutputFromArea, readablePower } from "../../utils/PowerUtils";
import { SystemType } from "../../types/solar.traits";

function GeometryStatsInfoBoxInternal({ layers, systemType, panelEfficiency }: { layers: L.Layer[], systemType: SystemType, panelEfficiency: number }): JSX.Element {
  const area = layers ? sumAreas(layers) : 0;
  return (
    <MapInfoBox>
      <Card.Title>Estimated Stats:</Card.Title>
      <Card.Text>Area: {readableAreaMetric(area)}</Card.Text>
      <Card.Text>Power: {readablePower(estimatePowerOutputFromArea(area, systemType.coverageRatio, panelEfficiency))}</Card.Text>
      <Card.Text>Annual Evaporation Savings: {"~" + readableVolumeMetric(calculateYearlyEvaporation(area))} ({(calculateYearlyEvaporation(area) * L_TO_ACRE_FEET_MULTIPLIER).toFixed(0)} Acre-ft)</Card.Text>
    </MapInfoBox>
  );
}

export function GeometryStatsInfoBox({ layers, systemType, panelEfficiency }: { layers: L.Layer[], systemType: SystemType, panelEfficiency: number }): JSX.Element {
  return (
    <MapInfoBoxContainer>
      <GeometryStatsInfoBoxInternal layers={layers} systemType={systemType} panelEfficiency={panelEfficiency} />
    </MapInfoBoxContainer>
  );
}

export function ProjectInfoBox({ project }: { project: Project }): JSX.Element {
  return (
    <MapInfoBoxContainer>
      <GeometryStatsInfoBoxInternal layers={project.toFeatureGroup().getLayers()} systemType={project.systemType} panelEfficiency={project.panelEfficiency} />
      <MapInfoBox>
        <Card.Title>Project Info:</Card.Title>
        <Card.Text>Creator: {project.owner}</Card.Text>
        <Card.Text>System Type: {project.systemType.displayName}</Card.Text>
        <Card.Text>Water Owner: {project.waterOwner}</Card.Text>
        <Card.Text>Water Owner Type: {project.waterOwnerType.displayName}</Card.Text>
        <Card.Text>Water Owner Contact Info: {project.waterOwnerContactInfo}</Card.Text>
        <Card.Text>Usage Type: {project.usageType.displayName}</Card.Text>
        <Card.Text>Border Type: {project.borderType.displayName}</Card.Text>
        <Card.Text>Waterbody Type: {project.waterbodyType.displayName}</Card.Text>
        <Card.Text>Classification: {project.classification.displayName}</Card.Text>
        <Card.Text>Security Type: {project.securityType.join(', ')}</Card.Text>
      </MapInfoBox>
    </MapInfoBoxContainer>
  );
}
