import { Col, Row } from 'react-bootstrap';
import ProjectEditorSidebar from './ProjectEditorSidebar';
import ProjectEditorTool from './ProjectEditorTool';
import { useState } from 'react';
import { Project } from '../../utils/ProjectClient';
import ProjectMap from './ProjectMap';
import { MapSidebar } from '../../generic/Layout';
import { useRouteLoaderData } from 'react-router-dom';
import { GeometryStatsInfoBox } from './ProjectInfoBox';
import { SystemType } from '../../types/solar.traits';


export function ProjectEditor() {
  const [editableLayers, setEditableLayers] = useState<L.Layer[]>([]);
  const [systemType, setSystemType] = useState(SystemType.CANOPY);
  const [panelEfficiency, setPanelEfficiency] = useState<number>(0.18);
  const project = useRouteLoaderData('project_selected') as Project;

  // See Layout.tsx for class definitions
  return (
    <Row className='flex-fill' style={{ minHeight: '0' }}>
      <MapSidebar>
        <ProjectEditorSidebar projectLayers={editableLayers} systemTypeCallback={setSystemType} panelEfficiencyCallback={setPanelEfficiency} project={project} />
      </MapSidebar>
      <Col className='gx-0'>
        <ProjectMap>
          <ProjectEditorTool layerUpdateCallback={setEditableLayers} project={project} />
          <GeometryStatsInfoBox layers={editableLayers} systemType={systemType} panelEfficiency={panelEfficiency} />
        </ProjectMap>
      </Col>
    </Row>
  );
}
