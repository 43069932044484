import L from 'leaflet';
import { useState, useEffect, useContext } from 'react';
import { Accordion, Button, ButtonGroup, Form } from 'react-bootstrap';
import { LoggedInUserContext } from '../../App';
import { useNavigate } from 'react-router';
import { Form as RouterForm } from 'react-router-dom';
import { DEFAULT_PROJECT_VALUES, Project } from '../../utils/ProjectClient';
import { BorderType, SecurityType, UsageType, WaterOwnerType, Classification, WaterbodyType } from '../../types/waterbody.traits';
import { layerToFeature } from '../../utils/GeoUtils';
import { SolarPanelType, SystemType } from '../../types/solar.traits';
import { EconomicModel } from '../../types/report.traits';

function handleError(err: any): void {
  console.error(err);
  if (err.response.status === 409) {
    alert('An project with that name already exists.');
  } else if (err.response.status >= 400 && err.response.status < 500) {
    alert('An unknown issue occurred while saving the project.');
  } else {
    alert('An unknown error occurred.');
  }
}

interface ProjectSidebarProps {
  projectLayers: L.Layer[],
  systemTypeCallback: (systemType: SystemType) => void,
  panelEfficiencyCallback: (panelEfficiency: number) => void,
  project?: Project
}

export default function ProjectEditorSidebar({ projectLayers, systemTypeCallback, panelEfficiencyCallback, project }: ProjectSidebarProps): JSX.Element {
  const [projectName, setProjectName] = useState(DEFAULT_PROJECT_VALUES.name);
  const [systemType, setSystemType] = useState(DEFAULT_PROJECT_VALUES.systemType);
  const [operationalLife, setOperationalLife] = useState<number>(DEFAULT_PROJECT_VALUES.operationalLife);
  const [panelEfficiency, setPanelEfficiency] = useState<number>(DEFAULT_PROJECT_VALUES.panelEfficiency);
  const [waterOwner, setWaterOwner] = useState(DEFAULT_PROJECT_VALUES.waterOwner);
  const [waterOwnerType, setWaterOwnerType] = useState(DEFAULT_PROJECT_VALUES.waterOwnerType);
  const [waterOwnerContactInfo, setWaterOwnerContactInfo] = useState(DEFAULT_PROJECT_VALUES.waterOwnerContactInfo);
  const [usageType, setUsageType] = useState(DEFAULT_PROJECT_VALUES.usageType);
  const [borderType, setBorderType] = useState(DEFAULT_PROJECT_VALUES.borderType);
  const [waterbodyType, setWaterbodyType] = useState(DEFAULT_PROJECT_VALUES.waterbodyType);
  const [classification, setClassification] = useState(DEFAULT_PROJECT_VALUES.classification);
  const [securityType, setSecurityType] = useState<SecurityType[]>(DEFAULT_PROJECT_VALUES.securityType);
  const [solarPanelType, setSolarPanelType] = useState(DEFAULT_PROJECT_VALUES.solarPanelType);
  const [losses, setLosses] = useState<number>(DEFAULT_PROJECT_VALUES.losses);
  const [panelTilt, setPanelTilt] = useState<number>(DEFAULT_PROJECT_VALUES.panelTilt);
  const [panelAzimuth, setPanelAzimuth] = useState<number>(DEFAULT_PROJECT_VALUES.panelAzimuth);
  const [dcToAcRatio, setDcToAcRatio] = useState<number>(DEFAULT_PROJECT_VALUES.dcToAcRatio);
  const [groundCoverageRatio, setGroundCoverageRatio] = useState<number>(DEFAULT_PROJECT_VALUES.groundCoverageRatio);
  const [inverterEfficiency, setInverterEfficiency] = useState<number>(DEFAULT_PROJECT_VALUES.inverterEfficiency);
  const [bifaciality, setBifaciality] = useState<boolean>(DEFAULT_PROJECT_VALUES.bifaciality);
  const [albedo, setAlbedo] = useState<number>(DEFAULT_PROJECT_VALUES.albedo);
  const [soiling, setSoiling] = useState<number>(DEFAULT_PROJECT_VALUES.soiling);

  const [economicModel, setEconomicModel] = useState(DEFAULT_PROJECT_VALUES.economicConfig.model);

  const [panelCostPerWatt, setPanelCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.panelCostPerWatt);
  const [inverterCostPerWatt, setInverterCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.inverterCostPerWatt);
  const [rackingCostPerWatt, setRackingCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.rackingCostPerWatt);
  const [anchoringCostPerWatt, setAnchoringCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.anchoringCostPerWatt);
  const [BOS_elecCostPerWatt, setBOS_elecCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.BOS_elecCostPerWatt);
  const [installLaborCostPerWatt, setInstallLaborCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.installLaborCostPerWatt);
  const [substationCostPerWatt, setSubstationCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.substationCostPerWatt);
  const [contingencyCostPerWatt, setContingencyCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.directCapitalCosts.contingencyCostPerWatt);

  const [overheadCostPerWatt, setOverheadCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.indirectCapitalCosts.overheadCostPerWatt);
  const [constructionCostPerWatt, setConstructionCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.indirectCapitalCosts.constructionCostPerWatt);
  const [assetManagementCostPerWatt, setAssetManagementCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.indirectCapitalCosts.assetManagementCostPerWatt);
  const [nonRecurringEngineeringCostPerWatt, setNonRecurringEngineeringCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.indirectCapitalCosts.nonRecurringEngineeringCostPerWatt);
  const [piiCostPerWatt, setPiiCostPerWatt] = useState(DEFAULT_PROJECT_VALUES.economicConfig.indirectCapitalCosts.piiCostPerWatt);

  const [salesTaxBasis, setSalesTaxBasis] = useState(DEFAULT_PROJECT_VALUES.economicConfig.salesTax.salesTaxBasis);
  const [salesTaxRate, setSalesTaxRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.salesTax.salesTaxRate);

  const [federalSubsidyRate, setFederalSubsidyRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.subsidy.federalSubsidyRate);
  const [stateSubsidyRate, setStateSubsidyRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.subsidy.stateSubsidyRate);

  const [operationAndMaintenanceCostPerKWYear, setOperationAndMaintenanceCostPerKWYear] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.operationAndMaintenanceCostPerKWYear);
  const [loanInterestRate, setLoanInterestRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.loanInterestRate);
  const [loanPercentSystemCost, setLoanPercentSystemCost] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.loanPercentSystemCost);
  const [downPayment, setDownPayment] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.downPayment);
  const [loanTerm, setLoanTerm] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.loanTerm);
  const [federalIncomeTaxRate, setFederalIncomeTaxRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.federalIncomeTaxRate);
  const [stateIncomeTaxRate, setStateIncomeTaxRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.recurringCosts.stateIncomeTaxRate);

  const [electricityValuePerKWh, setElectricityValuePerKWh] = useState(DEFAULT_PROJECT_VALUES.economicConfig.revenue.electricityValuePerKWh);
  const [escalationRate, setEscalationRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.revenue.escalationRate);
  const [degradationRateFirstYear, setDegradationRateFirstYear] = useState(DEFAULT_PROJECT_VALUES.economicConfig.revenue.degradationRateFirstYear);
  const [degradationRateOngoing, setDegradationRateOngoing] = useState(DEFAULT_PROJECT_VALUES.economicConfig.revenue.degradationRateOngoing);

  const [waterCostPerAcreFoot, setWaterCostPerAcreFoot] = useState(DEFAULT_PROJECT_VALUES.economicConfig.secondarySavings.waterCostPerAcreFoot);
  const [mitigationCostPerSqMeter, setMitigationCostPerSqMeter] = useState(DEFAULT_PROJECT_VALUES.economicConfig.secondarySavings.mitigationCostPerSqMeter);
  const [landCostPerAcre, setLandCostPerAcre] = useState(DEFAULT_PROJECT_VALUES.economicConfig.secondarySavings.landCostPerAcre);

  const [discountRate, setDiscountRate] = useState(DEFAULT_PROJECT_VALUES.economicConfig.financialMetrics.discountRate);


  const loggedInUser = useContext(LoggedInUserContext);
  const navigate = useNavigate();

  // When the project is first loaded, set the project name and owner
  useEffect(() => {
    if (project) {
      setProjectName(project.name);
      setSystemType(project.systemType);
      setOperationalLife(project.operationalLife);
      setPanelEfficiency(project.panelEfficiency);
      setWaterOwner(project.waterOwner || '');
      setWaterOwnerType(project.waterOwnerType);
      setWaterOwnerContactInfo(project.waterOwnerContactInfo || '');
      setUsageType(project.usageType);
      setBorderType(project.borderType);
      setWaterbodyType(project.waterbodyType);
      setClassification(project.classification);
      setSecurityType(project.securityType);
      setSolarPanelType(project.solarPanelType);
      setLosses(project.losses);
      setPanelTilt(project.panelTilt);
      setPanelAzimuth(project.panelAzimuth);
      setDcToAcRatio(project.dcToAcRatio);
      setGroundCoverageRatio(project.groundCoverageRatio);
      setInverterEfficiency(project.inverterEfficiency);
      setBifaciality(project.bifaciality);
      setAlbedo(project.albedo);
      setSoiling(project.soiling);
      setEconomicModel(project.economicConfig.model);
      setPanelCostPerWatt(project.economicConfig.directCapitalCosts.panelCostPerWatt);
      setInverterCostPerWatt(project.economicConfig.directCapitalCosts.inverterCostPerWatt);
      setRackingCostPerWatt(project.economicConfig.directCapitalCosts.rackingCostPerWatt);
      setAnchoringCostPerWatt(project.economicConfig.directCapitalCosts.anchoringCostPerWatt);
      setBOS_elecCostPerWatt(project.economicConfig.directCapitalCosts.BOS_elecCostPerWatt);
      setInstallLaborCostPerWatt(project.economicConfig.directCapitalCosts.installLaborCostPerWatt);
      setSubstationCostPerWatt(project.economicConfig.directCapitalCosts.substationCostPerWatt);
      setContingencyCostPerWatt(project.economicConfig.directCapitalCosts.contingencyCostPerWatt);
      setOverheadCostPerWatt(project.economicConfig.indirectCapitalCosts.overheadCostPerWatt);
      setConstructionCostPerWatt(project.economicConfig.indirectCapitalCosts.constructionCostPerWatt);
      setAssetManagementCostPerWatt(project.economicConfig.indirectCapitalCosts.assetManagementCostPerWatt);
      setNonRecurringEngineeringCostPerWatt(project.economicConfig.indirectCapitalCosts.nonRecurringEngineeringCostPerWatt);
      setPiiCostPerWatt(project.economicConfig.indirectCapitalCosts.piiCostPerWatt);
      setSalesTaxBasis(project.economicConfig.salesTax.salesTaxBasis);
      setSalesTaxRate(project.economicConfig.salesTax.salesTaxRate);
      setFederalSubsidyRate(project.economicConfig.subsidy.federalSubsidyRate);
      setStateSubsidyRate(project.economicConfig.subsidy.stateSubsidyRate);
      setOperationAndMaintenanceCostPerKWYear(project.economicConfig.recurringCosts.operationAndMaintenanceCostPerKWYear);
      setLoanInterestRate(project.economicConfig.recurringCosts.loanInterestRate);
      setLoanPercentSystemCost(project.economicConfig.recurringCosts.loanPercentSystemCost);
      setDownPayment(project.economicConfig.recurringCosts.downPayment);
      setLoanTerm(project.economicConfig.recurringCosts.loanTerm);
      setFederalIncomeTaxRate(project.economicConfig.recurringCosts.federalIncomeTaxRate);
      setStateIncomeTaxRate(project.economicConfig.recurringCosts.stateIncomeTaxRate);
      setElectricityValuePerKWh(project.economicConfig.revenue.electricityValuePerKWh);
      setEscalationRate(project.economicConfig.revenue.escalationRate);
      setDegradationRateFirstYear(project.economicConfig.revenue.degradationRateFirstYear);
      setDegradationRateOngoing(project.economicConfig.revenue.degradationRateOngoing);
      setWaterCostPerAcreFoot(project.economicConfig.secondarySavings.waterCostPerAcreFoot);
      setMitigationCostPerSqMeter(project.economicConfig.secondarySavings.mitigationCostPerSqMeter);
      setLandCostPerAcre(project.economicConfig.secondarySavings.landCostPerAcre);
      setDiscountRate(project.economicConfig.financialMetrics.discountRate);
    }
  }, [project]);

  // Surface changes to parent component
  // This is needed because we need to calculate the geometry stats from the map component
  useEffect(() => {
    systemTypeCallback(systemType);
  }, [systemType]);

  useEffect(() => {
    panelEfficiencyCallback(panelEfficiency);
  }, [panelEfficiency]);

  function saveProject(): void {
    const features = projectLayers.map(layerToFeature);

    if (projectName.length === 0) {
      return;
    }

    const newProject = new Project(project ? project.id : -1,
      projectName,
      features,
      systemType,
      operationalLife,
      panelEfficiency,
      project ? project.owner : loggedInUser!.username,
      waterOwner,
      waterOwnerType,
      waterOwnerContactInfo,
      usageType,
      borderType,
      waterbodyType,
      classification,
      securityType,
      solarPanelType,
      losses,
      panelTilt,
      panelAzimuth,
      dcToAcRatio,
      groundCoverageRatio,
      inverterEfficiency,
      bifaciality,
      albedo,
      soiling,
      {
        model: economicModel,
        directCapitalCosts: {
          panelCostPerWatt: panelCostPerWatt,
          inverterCostPerWatt: inverterCostPerWatt,
          rackingCostPerWatt: rackingCostPerWatt,
          anchoringCostPerWatt: anchoringCostPerWatt,
          BOS_elecCostPerWatt: BOS_elecCostPerWatt,
          installLaborCostPerWatt: installLaborCostPerWatt,
          substationCostPerWatt: substationCostPerWatt,
          contingencyCostPerWatt: contingencyCostPerWatt,
        },
        indirectCapitalCosts: {
          overheadCostPerWatt: overheadCostPerWatt,
          constructionCostPerWatt: constructionCostPerWatt,
          assetManagementCostPerWatt: assetManagementCostPerWatt,
          nonRecurringEngineeringCostPerWatt: nonRecurringEngineeringCostPerWatt,
          piiCostPerWatt: piiCostPerWatt,
        },
        salesTax: {
          salesTaxBasis: salesTaxBasis,
          salesTaxRate: salesTaxRate,
        },
        subsidy: {
          federalSubsidyRate: federalSubsidyRate,
          stateSubsidyRate: stateSubsidyRate,
        },
        recurringCosts: {
          operationAndMaintenanceCostPerKWYear: operationAndMaintenanceCostPerKWYear,
          loanInterestRate: loanInterestRate,
          loanPercentSystemCost: loanPercentSystemCost,
          downPayment: downPayment,
          loanTerm: loanTerm,
          federalIncomeTaxRate: federalIncomeTaxRate,
          stateIncomeTaxRate: stateIncomeTaxRate,
        },
        revenue: {
          electricityValuePerKWh: electricityValuePerKWh,
          escalationRate: escalationRate,
          degradationRateFirstYear: degradationRateFirstYear,
          degradationRateOngoing: degradationRateOngoing,
        },
        secondarySavings: {
          waterCostPerAcreFoot: waterCostPerAcreFoot,
          mitigationCostPerSqMeter: mitigationCostPerSqMeter,
          landCostPerAcre: landCostPerAcre,
        },
        financialMetrics: {
          discountRate: discountRate,
        },
      }
    );

    // In case this is a new project, when complete we navigate to the edit page for the project so that subsequent saves will update it.
    loggedInUser?.apiClient.getProjectClient().createOrUpdateProject(newProject).then(inst => navigate('/projects/')).catch(handleError);
  }

  return (
    <RouterForm method='post' action={'/projects/' + (project?.id || '') + 'edit'}>
      <h2>Options:</h2>
      <Form.Group className="mb-3">
        <Form.Label>Project Name</Form.Label>
        <Form.Control required type="text" placeholder="My Aquavoltaics Project" value={projectName} onChange={(e) => setProjectName(e.target.value)} isInvalid={projectName.length === 0} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>System Type</Form.Label>
        <Form.Select value={systemType.id} onChange={(e) => setSystemType(SystemType.fromId(e.target.value))}>
          {
            SystemType.allValues().map((key) => {
              return <option value={key.id}>{key.displayName}</option>
            })
          }
        </Form.Select>
      </Form.Group>

      {formGroupInt("Operational Life", operationalLife, setOperationalLife)}

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Site</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>Water Owner</Form.Label>
              <Form.Control type="text" value={waterOwner} onChange={(e) => setWaterOwner(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Water Owner Type</Form.Label>
              <Form.Select value={waterOwnerType.id} onChange={(e) => setWaterOwnerType(WaterOwnerType.fromId(e.target.value))}>
                {
                  WaterOwnerType.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Water Owner Contact Info</Form.Label>
              <Form.Control type="text" value={waterOwnerContactInfo} onChange={(e) => setWaterOwnerContactInfo(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Usage Type</Form.Label>
              <Form.Select value={usageType.id} onChange={(e) => setUsageType(UsageType.fromId(e.target.value))}>
                {
                  UsageType.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Border Type</Form.Label>
              <Form.Select value={borderType.id} onChange={(e) => setBorderType(BorderType.fromId(e.target.value))}>
                {
                  BorderType.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Waterbody Type</Form.Label>
              <Form.Select value={waterbodyType.id} onChange={(e) => setWaterbodyType(WaterbodyType.fromId(e.target.value))}>
                {
                  WaterbodyType.allValues().map((key) =>
                    <option key={key.id} value={key.id}>{key.displayName}</option>
                  )
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Classification</Form.Label>
              <Form.Select value={classification.id} onChange={(e) => setClassification(Classification.fromId(e.target.value))}>
                {
                  Classification.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Security Type</Form.Label>
              {
                SecurityType.allValues().map((key) => {
                  return <Form.Check type="checkbox" label={key.displayName} checked={securityType.includes(key)} onChange={(e) => setSecurityType(e.target.checked ? (securityType.concat(key)) : securityType.filter((e) => e !== key))} />
                })
              }
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Solar</Accordion.Header>
          <Accordion.Body>

            <Form.Group className="mb-3">
              <Form.Label>Solar Panel Type</Form.Label>
              <Form.Select value={solarPanelType.id} onChange={(e) => setSolarPanelType(SolarPanelType.fromId(e.target.value))}>
                {
                  SolarPanelType.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            {formGroupPercent("Panel Efficiency", panelEfficiency, setPanelEfficiency)}
            {formGroupPercent("Losses", losses, setLosses)}
            {formGroupInt("Panel Tilt", panelTilt, setPanelTilt)}
            {formGroupInt("Panel Azimuth", panelAzimuth, setPanelAzimuth)}
            {formGroupFloat("DC to AC Ratio", dcToAcRatio, setDcToAcRatio)}
            {formGroupPercent("Ground Coverage", groundCoverageRatio, setGroundCoverageRatio)}
            {formGroupPercent("Inverter Efficiency", inverterEfficiency, setInverterEfficiency)}

            <Form.Group className="mb-3">
              <Form.Label>Bifaciality</Form.Label>
              <Form.Check type="checkbox" checked={bifaciality} onChange={(e) => setBifaciality(e.target.checked)} />
            </Form.Group>

            {formGroupFloat("Albedo", albedo, setAlbedo)}
            {formGroupFloat("Soiling", soiling, setSoiling)}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>CAPEX</Accordion.Header>
          <Accordion.Body>

            {formGroupFloat("Panel Cost per W", panelCostPerWatt, setPanelCostPerWatt)}
            {formGroupFloat("Inverter Cost per W", inverterCostPerWatt, setInverterCostPerWatt)}
            {formGroupFloat("Racking Cost per W", rackingCostPerWatt, setRackingCostPerWatt)}
            {formGroupFloat("Anchoring Cost per W", anchoringCostPerWatt, setAnchoringCostPerWatt)}
            {formGroupFloat("BOS Elec Cost per W", BOS_elecCostPerWatt, setBOS_elecCostPerWatt)}
            {formGroupFloat("Install Labor Cost per W", installLaborCostPerWatt, setInstallLaborCostPerWatt)}
            {formGroupFloat("Substation Cost per W", substationCostPerWatt, setSubstationCostPerWatt)}
            {formGroupFloat("Contingency Cost per W", contingencyCostPerWatt, setContingencyCostPerWatt)}

            {formGroupFloat("Overhead Cost per W", overheadCostPerWatt, setOverheadCostPerWatt)}
            {formGroupFloat("Construction Cost per W", constructionCostPerWatt, setConstructionCostPerWatt)}
            {formGroupFloat("Asset Management Cost per W", assetManagementCostPerWatt, setAssetManagementCostPerWatt)}
            {formGroupFloat("Non-Recurring Engineering Cost per W", nonRecurringEngineeringCostPerWatt, setNonRecurringEngineeringCostPerWatt)}
            {formGroupFloat("PII Cost per W", piiCostPerWatt, setPiiCostPerWatt)}

          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Economics</Accordion.Header>
          <Accordion.Body>

            <Form.Group className="mb-3">
              <Form.Label>Economic Model</Form.Label>
              <Form.Select value={economicModel.id} onChange={(e) => setEconomicModel(EconomicModel.fromId(e.target.value))}>
                {
                  EconomicModel.allValues().map((key) => {
                    return <option value={key.id}>{key.displayName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>

            {formGroupPercent("Sales Tax Basis", salesTaxBasis, setSalesTaxBasis)}
            {formGroupPercent("Sales Tax Rate", salesTaxRate, setSalesTaxRate)}

            {formGroupPercent("Federal Subsidy Rate", federalSubsidyRate, setFederalSubsidyRate)}
            {formGroupPercent("State Subsidy Rate", stateSubsidyRate, setStateSubsidyRate)}

            {formGroupFloat("Operation and Maintenance Cost per KW Year", operationAndMaintenanceCostPerKWYear, setOperationAndMaintenanceCostPerKWYear)}
            {formGroupPercent("Loan Interest Rate", loanInterestRate, setLoanInterestRate)}
            {formGroupPercent("Loan Percent System Cost", loanPercentSystemCost, setLoanPercentSystemCost)}
            {formGroupFloat("Down Payment", downPayment, setDownPayment)}
            {formGroupInt("Loan Term", loanTerm, setLoanTerm)}
            {formGroupPercent("Federal Income Tax Rate", federalIncomeTaxRate, setFederalIncomeTaxRate)}
            {formGroupPercent("State Income Tax Rate", stateIncomeTaxRate, setStateIncomeTaxRate)}

            {formGroupFloat("Electricity Value per KWh", electricityValuePerKWh, setElectricityValuePerKWh)}
            {formGroupPercent("Escalation Rate", escalationRate, setEscalationRate)}
            {formGroupPercent("Degradation Rate First Year", degradationRateFirstYear, setDegradationRateFirstYear)}
            {formGroupPercent("Degradation Rate Ongoing", degradationRateOngoing, setDegradationRateOngoing)}

            {formGroupFloat("Water Cost per Acre-ft", waterCostPerAcreFoot, setWaterCostPerAcreFoot)}
            {formGroupFloat("Mitigation Cost per Sq Meter", mitigationCostPerSqMeter, setMitigationCostPerSqMeter)}
            {formGroupFloat("Land Cost per Acre", landCostPerAcre, setLandCostPerAcre)}

            {formGroupPercent("Discount Rate", discountRate, setDiscountRate)}

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <ButtonGroup className="mt-3">
        <Button variant='primary' onClick={saveProject} name='intent' value='save'>Save</Button>
      </ButtonGroup>
    </RouterForm>
  );
};

/**
 * Generates a form group with a label and a ddecimal input.
 * 
 * @param label Form item label
 * @param value Current value
 * @param onChange Value change handler
 * @returns element
 */
function formGroupFloat(label: string, value: number, onChange: (value: number) => void): JSX.Element {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control type="number" step="0.01" value={value} onChange={(e) => onChange(parseFloat(e.target.value))} />
    </Form.Group>
  )
}

/**
 * Like formGroupFloat, but the value is multiplied by 100 before being displayed and the input value is divided by 100 before being saved.
 * 
 * @param label Form item label
 * @param value Current value
 * @param onChange Value change handler
 * @returns element
 */
function formGroupPercent(label: string, value: number, onChange: (value: number) => void): JSX.Element {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label} (%)</Form.Label>
      <Form.Control type="number" step="0.01" value={(value * 100).toFixed(2)} onChange={(e) => onChange(parseFloat(e.target.value) / 100)} />
    </Form.Group>
  )
}

/**
 * Generates a form group with a label and an integer input.
 * 
 * @param label Form item label
 * @param value Current value
 * @param onChange Value change handler
 * @returns element
 */
function formGroupInt(label: string, value: number, onChange: (value: number) => void): JSX.Element {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control type="number" value={value} onChange={(e) => onChange(parseInt(e.target.value))} />
    </Form.Group>
  )
}
