import { EconomicAnalysis, TechnicalAnalysis } from "../types/report.traits";
import { ApiClient } from "./ApiClient";

export class ReportClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  getEconReport(projectId: number, annualEnergyProductionKWh: number): Promise<EconomicAnalysis> {
    console.debug('getting Econ Report for project', projectId);
    return this.apiClient.get<EconomicAnalysis>('api/reports/economic/' + projectId, { annualEnergyProductionKWh }).then(r => r.data);
  }
  
  getTechReport(projectId: number): Promise<TechnicalAnalysis> {
    console.debug('getting Tech Report for project', projectId);
    return this.apiClient.get<TechnicalAnalysis>('api/reports/technical/' + projectId).then(r => r.data);
  }
}
