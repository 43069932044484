import { YearFinances } from "../../../../types/report.traits";
import { ResponsiveBar } from '@nivo/bar'

export function YearlyEconomicsGraphs({ lifetimeFinances }: { lifetimeFinances?: YearFinances[] }) {
  if (lifetimeFinances) {
    const lfDictified = lifetimeFinances.map((v) => ({ ...v }));

    const costs = [
      {
        id: 'O&M',
        data: lifetimeFinances.map((v, i) => ({ x: i + 1, y: v.operationAndMaintenanceCost }))
      },
    ];

    return (
      <>
        <h2>Income</h2>
        <div style={{ height: '300px' }}>
          <ResponsiveBar
            data={lfDictified}
            keys={[
              "grossProfit",
              "npvCumulative",
              "netProfitCumulative",
            ]}
            indexBy="year"
            margin={{ top: 50, right: 130, bottom: 80, left: 100 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Year',
              legendPosition: 'middle',
              legendOffset: 32,
              truncateTickAt: 0
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Cost ($)',
              legendPosition: 'middle',
              legendOffset: -80,
              truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
          />
        </div>

        <h2>O&M Costs</h2>
        <div style={{ height: '300px' }}>
          <ResponsiveBar
            data={lfDictified}
            keys={[
              "revenue",
              "operationAndMaintenanceCost",
              "waterCost",
              "mitigationCost",
              "federalIncomeTaxCost",
              "stateIncomeTaxCost",
              "principalPayment",
              "interestPayment",
              "principalAndInterestPayment",
              "grossProfit",
              "cashFlow",
              "npv",
            ]}
            indexBy="year"
            margin={{ top: 50, right: 130, bottom: 80, left: 100 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Year',
              legendPosition: 'middle',
              legendOffset: 32,
              truncateTickAt: 0
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Cost ($)',
              legendPosition: 'middle',
              legendOffset: -80,
              truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
          />
        </div>
      </>
    );
  }

  return <>Loading...</>;
}