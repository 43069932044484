import { ResponsiveLine } from "@nivo/line";
import { PVWattsResponseContent } from "../../../../utils/PVWattsClient";

export function PowerGenerationReport({ monthlyData }: { monthlyData?: PVWattsResponseContent }) {
  if (monthlyData) {
    const monthlyPowerDataMW = [
      {
        id: 'AC',
        data: monthlyData.outputs.ac_monthly.map((value, index) => ({ x: index + 1, y: value / 1000 }))
      },
      {
        id: 'DC',
        data: monthlyData.outputs.dc_monthly.map((value, index) => ({ x: index + 1, y: value / 1000 }))
      }
    ];

    const monthlyPOAData = [
      {
        id: 'POA',
        data: monthlyData.outputs.poa_monthly.map((value, index) => ({ x: index + 1, y: value }))
      }
    ];

    const monthlySolarRadiationData = [
      {
        id: 'Solar Radiation',
        data: monthlyData.outputs.solrad_monthly.map((value, index) => ({ x: index + 1, y: value }))
      }
    ];

    return (
      <>
        <h2>Monthly Power Generation</h2>
        <div style={{ height: '300px' }}>
          <ResponsiveLine
            data={monthlyPowerDataMW}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{
              format: '%m',
              precision: 'month',
              type: 'time',
              useUTC: false
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: true,
              reverse: false
            }}
            yFormat=" >-.2f"
            xFormat="time:%b"
            axisBottom={{
              format: '%b',
              legendOffset: -12,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Megawatt Hours',
              legendOffset: -50,
              legendPosition: 'middle',
              truncateTickAt: 0,
            }}
            pointSize={10}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </div>

        <h2>Monthly Plane of Array Incidence</h2>
        <div style={{ height: '300px' }}>
          <ResponsiveLine
            data={monthlyPOAData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{
              format: '%m',
              precision: 'month',
              type: 'time',
              useUTC: false
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: true,
              reverse: false
            }}
            yFormat=" >-.2f"
            xFormat="time:%b"
            axisBottom={{
              format: '%b',
              legendOffset: -12,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Killowatt Hours per squaare meter',
              legendOffset: -50,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            pointSize={10}
            enableTouchCrosshair={true}
            useMesh={true}
          />
        </div>

        <h2>Monthly Solar Radiation</h2>
        <div style={{ height: '300px' }}>
          <ResponsiveLine
            data={monthlySolarRadiationData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{
              format: '%m',
              precision: 'month',
              type: 'time',
              useUTC: false
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: true,
              reverse: false
            }}
            yFormat=" >-.2f"
            xFormat="time:%b"
            axisBottom={{
              format: '%b',
              legendOffset: -12,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Killowatt Hours per squaare meter per day',
              legendOffset: -50,
              legendPosition: 'middle',
              truncateTickAt: 0
            }}
            pointSize={10}
            enableTouchCrosshair={true}
            useMesh={true}
          />
        </div>
      </>
    );
  }

  return <>Loading...</>;
}