import { Card, Col, Row } from 'react-bootstrap';
import { MapContainer, ZoomControl, GeoJSON, Tooltip, useMapEvents } from "react-leaflet"
import { BackgroundLayersControl } from "../../generic/mapping/BackgroundLayersControl"
import styles from '../../generic/mapping/Map.module.css';
import { MapPosition, MapPositionTracker } from '../../generic/mapping/MapPosition';
import { LoggedInUserContext } from '../../App';
import React from 'react';
import { latLngBoundsToGeoJsonPolygon } from '../../utils/GeoUtils';
import { LatLngBounds } from 'leaflet';
import { MapInfoBox, MapInfoBoxContainer } from '../../generic/mapping/MapBoxes';
import { CountyFeature } from '../../types/governmentalunits.traits';
import { SearchBox } from '../../generic/mapping/SearchBox';
import * as turf from "@turf/turf";

const DEFAULT_REGION_COLOR = 'purple';
const PANE = 'overlayPane';

interface HydroStats {
  areasqkm: number;
  count: number;
}

export function RegionInfoBox({ regions }: { regions?: CountyFeature[] }): JSX.Element {
  const user = React.useContext(LoggedInUserContext);
  const [hydroStats, setHydroStats] = React.useState<HydroStats>();

  React.useEffect(() => {
    setHydroStats(undefined);
    if (!user || !regions || regions.length === 0) {
      return;
    }

    const totalRegion = regions.length > 1 ? turf.union(turf.featureCollection(regions)) : regions[0];

    user.apiClient.getWFSClient().getNHDWaterbodies(totalRegion!.geometry)
      .then((data) => {
        setHydroStats(data.features.reduce<HydroStats>((acc, feature) => {
          return {
            areasqkm: acc.areasqkm + feature.properties.areasqkm,
            count: acc.count + 1
          }
        },
          {
            areasqkm: 0,
            count: 0,
          }
        ));
      });
  }, [user, regions])

  if (!regions || regions.length === 0) {
    return <></>;
  }

  return (
    <MapInfoBoxContainer>
      <MapInfoBox>
        <Card.Title>Region Info:</Card.Title>
        <Card.Text>Name: {regions.length > 1 ? '(many)' : regions[0].properties?.gnis_name}</Card.Text>
        <Card.Text>Population: {regions.reduce((acc, region) => acc + region.properties?.population, 0)}</Card.Text>
        <Card.Text>Area: {regions.reduce((acc, region) => acc + region.properties?.areasqkm, 0).toFixed(0)} km²</Card.Text>
        {
          hydroStats ?
            <>
              <Card.Text>Hydro Area: {hydroStats.areasqkm.toFixed(0)} km²</Card.Text>
              <Card.Text>Hydro Count: {hydroStats.count}</Card.Text>
            </> : <Card.Text>Loading hydro stats...</Card.Text>
        }
      </MapInfoBox>
    </MapInfoBoxContainer>
  );
}

export function GovernmentalUnits(): JSX.Element {
  const user = React.useContext(LoggedInUserContext);
  const [counties, setCounties] = React.useState<CountyFeature[]>([]);
  const [clickedRegions, setClickedRegions] = React.useState<CountyFeature[]>([]);
  const map = useMapEvents({
    moveend: (e) => {
      setMapBounds(e.target.getBounds());
    },
  });
  const [mapBounds, setMapBounds] = React.useState<LatLngBounds>(map.getBounds());

  // We do this in a react effect instead of a map event because we want this to happen when the page first loads even without moving. Also, the 'load' event doesn't fire for some reason.
  React.useEffect(() => {
    if (!user || !mapBounds) {
      return;
    }

    user.apiClient.getWFSClient().getCounties(latLngBoundsToGeoJsonPolygon(mapBounds))
      .then((data) => {
        // Needs to be new object to force re-render
        const newCounties = Array.from(counties);
        data.features.filter(f => f.id).filter(f => !newCounties.find(c => c.id === f.id)).forEach(f => newCounties.push(f));
        setCounties(newCounties);
      }).catch(console.error);
  }, [user, mapBounds]);

  return (
    <>
      <RegionInfoBox regions={clickedRegions} />
      {counties.map((feature) => {
        const isClicked = clickedRegions.some(r => r.id === feature.id);
        return (
          <GeoJSON
            key={feature.id}
            pathOptions={{ color: isClicked ? 'green' : DEFAULT_REGION_COLOR }}
            data={feature}
            pane={PANE}
            eventHandlers={{
              click: (e) => {
                if (isClicked) {
                  setClickedRegions(clickedRegions.filter(r => r.id !== feature.id));
                } else {
                  setClickedRegions([...clickedRegions, feature]);
                }
              },
              mouseover: (e) => {
                if (!isClicked) {
                  e.target.setStyle({ color: 'red' });
                }
              },
              mouseout: (e) => {
                if (!isClicked) {
                  e.target.setStyle({ color: DEFAULT_REGION_COLOR });
                }
              }
            }}
          >
            <Tooltip>{feature.properties?.gnis_name}</Tooltip>
          </GeoJSON>
        );
      })}
    </>
  );
}


export function GovUnitsExplorationTool(): JSX.Element {
  return (
    <Row className='flex-fill flex-grow-1'>
      <Col className='gx-0'>
        <MapContainer
          center={MapPosition.coords}
          zoom={MapPosition.zoom}
          maxZoom={25}
          zoomSnap={0.5}
          scrollWheelZoom={true}
          className={styles['map']}
          zoomControl={false}>
          <ZoomControl position='bottomright' />
          <BackgroundLayersControl position='bottomright' />
          <MapPositionTracker />
          <GovernmentalUnits />
          <SearchBox />
        </MapContainer>
      </Col>
    </Row>
  );
}
