import axios from "axios";
import { Project } from "./ProjectClient";
import { estimatePowerOutputForProject } from "./PowerUtils";


export interface PVWattsMonthlyOutput {
  ac_monthly: number[];
  dc_monthly: number[];
  poa_monthly: number[];
  solrad_monthly: number[];
  ac_annual: number;
  solrad_annual: number;
  capacity_factor: number;
}

export interface PVWattsResponseContent {
  outputs: PVWattsMonthlyOutput;
}

export class PVWattsClientError extends Error { }

export class PVWattsClient {
  public constructor(private apikey: string = 'INAKT0RFjb88hUxihItf48GBw7Ni5Ga3QT6SOY2P') {
  }

  public async getPVWattsMonthlyData(project: Project): Promise<PVWattsResponseContent> {
    const fg = project.toFeatureGroup();

    const capacity = estimatePowerOutputForProject(project);

    if (capacity > 500_000) {
      throw new PVWattsClientError('Project is too large for PVWatts. Base capacity: ' + capacity);
    }

    const resp = await axios.get<PVWattsResponseContent>('https://developer.nrel.gov/api/pvwatts/v8.json', {
      params: {
        api_key: this.apikey,
        system_capacity: capacity,
        module_type: project.solarPanelType.code,
        array_type: 0,
        losses: project.losses,
        tilt: project.panelTilt,
        azimuth: project.panelAzimuth,
        lat: fg.getBounds().getCenter().lat,
        lon: fg.getBounds().getCenter().lng,
        timeframe: 'monthly',
        dc_ac_ratio: project.dcToAcRatio,
        gcr: project.groundCoverageRatio,
        inv_eff: project.inverterEfficiency * 100,
        bifaciality: project.bifaciality ? 1 : 0,
        albedo: project.albedo,
        use_wf_albedo: 1,
        soiling: Array(12).fill(project.soiling).join('|'),
      },
    });

    return resp.data;
  }
}
