
// Unless otherwise specified, all costs are per watt DC
// All "rate" values are in perrcents in decimal form

export interface DirectCapitalCosts {
  panelCostPerWatt: number;
  inverterCostPerWatt: number;
  rackingCostPerWatt: number;
  anchoringCostPerWatt: number;
  BOS_elecCostPerWatt: number;
  installLaborCostPerWatt: number;
  substationCostPerWatt: number;
  contingencyCostPerWatt: number;
}

export interface IndirectCapitalCosts {
  overheadCostPerWatt: number;
  constructionCostPerWatt: number;
  assetManagementCostPerWatt: number;
  nonRecurringEngineeringCostPerWatt: number;
  piiCostPerWatt: number; // Permitting, inspection and interconnection
}

export interface SalesTax {
  salesTaxBasis: number;
  salesTaxRate: number;
}

export interface Subsidy {
  federalSubsidyRate: number;
  stateSubsidyRate: number;
}

export interface RecurringCosts {
  operationAndMaintenanceCostPerKWYear: number;  // For AC watts
  loanInterestRate: number;
  loanPercentSystemCost: number;
  downPayment: number;
  loanTerm: number;
  federalIncomeTaxRate: number;
  stateIncomeTaxRate: number;
}

export interface Revenue {
  electricityValuePerKWh: number;
  escalationRate: number;
  degradationRateFirstYear: number;
  degradationRateOngoing: number;
}

export interface SecondarySavings {
  waterCostPerAcreFoot: number;
  mitigationCostPerSqMeter: number;  // should also be per year
  landCostPerAcre: number;
}

export interface FinancialMetrics {
  discountRate: number;
}

export class EconomicModel {
  static GRANT = new EconomicModel('grant', 'Grant');
  static PPA_LOAN = new EconomicModel('ppa-loan', 'PPA Loan');
  static PPA_NO_LOAN = new EconomicModel('ppa-no-loan', 'PPA No Loan');
  static OFFSET_LOAN = new EconomicModel('offset-loan', 'Offset Loan');
  static OFFSET_NO_LOAN = new EconomicModel('offset-no-loan', 'Offset No Loan');

  constructor(public readonly id: string, public readonly displayName: string) { }

  static allValues(): EconomicModel[] {
    return [
      EconomicModel.GRANT,
      EconomicModel.PPA_LOAN,
      EconomicModel.PPA_NO_LOAN,
      EconomicModel.OFFSET_LOAN,
      EconomicModel.OFFSET_NO_LOAN
    ];
  }

  static fromId(id: string): EconomicModel {
    const found = EconomicModel.allValues().find((key) => key.id === id);
    if (!found) {
      throw new Error(`unknown type ${id}`);
    }
    return found;
  }

  toJson(): string {
    return this.id;
  }
}

export interface EconomicConfiguration {
  model: EconomicModel;
  directCapitalCosts: DirectCapitalCosts;
  indirectCapitalCosts: IndirectCapitalCosts;
  salesTax: SalesTax;
  subsidy: Subsidy;
  recurringCosts: RecurringCosts;
  revenue: Revenue;
  secondarySavings: SecondarySavings;
  financialMetrics: FinancialMetrics;
}

export const DEFAULT_ECONOMIC_CONFIGURATION: EconomicConfiguration = {
  model: EconomicModel.GRANT,
  directCapitalCosts: {
    panelCostPerWatt: 0.18,
    inverterCostPerWatt: 0.05,
    rackingCostPerWatt: 0.35,
    anchoringCostPerWatt: 0.15,
    BOS_elecCostPerWatt: 0.03,
    installLaborCostPerWatt: 0.16,
    substationCostPerWatt: 0.02,
    contingencyCostPerWatt: 0.01,
  },
  indirectCapitalCosts: {
    overheadCostPerWatt: 0.25,
    constructionCostPerWatt: 0.09,
    assetManagementCostPerWatt: 0.05,
    nonRecurringEngineeringCostPerWatt: 0.01,
    piiCostPerWatt: 0.03,
  },
  salesTax: {
    salesTaxBasis: 1,
    salesTaxRate: 0.0725,
  },
  subsidy: {
    federalSubsidyRate: 0.3,
    stateSubsidyRate: 0,
  },
  recurringCosts: {
    operationAndMaintenanceCostPerKWYear: 50,
    loanInterestRate: 0.07,
    loanPercentSystemCost: 1,
    downPayment: 200_000,
    loanTerm: 20,
    federalIncomeTaxRate: 0.21,
    stateIncomeTaxRate: 0.07,
  },
  revenue: {
    electricityValuePerKWh: 0.15,
    escalationRate: 0.05,
    degradationRateFirstYear: 0.01,
    degradationRateOngoing: 0.0035,
  },
  secondarySavings: {
    waterCostPerAcreFoot: 40,
    mitigationCostPerSqMeter: 0.5,
    landCostPerAcre: 10_000,
  },
  financialMetrics: {
    discountRate: 0.03,
  },
}

export interface YearFinances {
  year: number;
  degredation: number;
  energyYield: number;
  electricityValuePerKWh: number;
  revenue: number;
  operationAndMaintenanceCost: number;
  waterCost: number;
  mitigationCost: number;
  federalIncomeTaxCost: number;
  stateIncomeTaxCost: number;
  principalPayment: number;
  interestPayment: number;
  principalAndInterestPayment: number;
  grossProfit: number;
  cashFlow: number;
  netProfitCumulative: number;
  npv: number;
  npvCumulative: number;
}

export interface EconomicAnalysis {
  totalDirectCapitalCostPerWatt: number;
  totalDirectCapitalCosts: number;
  totalIndirectCapitalCostPerWatt: number;
  totalIndirectCapitalCosts: number;
  totalSalesTaxCost: number;
  totalCapitalCost: number;
  totalCapitalCostPerWatt: number;
  totalCapitalCostPerWattAC: number;
  totalInstalledCostSubsidized: number;
  totalInstalledCostSubsidizedPerWatt: number;
  totalInstalledCostSubsidizedPerWattAC: number;
  annualOperationAndMaintenanceCost: number;
  loanAmount: number;
  waterCostSavingsPerYear: number;
  mitigationCostSavingsPerYear: number;
  landAquisitionSavings: number;
  lifetimeFinances: YearFinances[];
}

export interface TechnicalAnalysis {
  annualEvaporationSavingsAcreftPerYear: number;
}
